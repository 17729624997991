import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { logoVertikal } from "../../assets";
import "./payment.css";
import {
  orderByTransCode,
  UpdateOrder,
  NotifNewOrderForOpr,
} from "../../config/Redux/action/paymentTrans";
import { getOprByOprId } from "../../config/Redux/action/getOprByOprId";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";

const ManualFinish = () => {
  const [order_id, setOrder_id]                                       = useState("");
  const [Token, setToken]                                             = useState("");
  const [tempDataUpdate, setTempDataUpdate]                           = useState();
  const [tempDataUpdateNotifNewOrder, setTempDataUpdateNotifNewOrder] = useState();
  const dispatch                                                      = useDispatch();
  const { loadingDataOrder, dataOrder }                               = useSelector((s) => s.PaymentTrans);

  const toHistory = async () => {
    window.ReactNativeWebView.postMessage("Finish");
  };

  // console.log('DATA ORDER',dataOrder)

  // GET CREATE DATE ORDER
  let create_date_plus_1 = new Date(dataOrder?.create_date);
  create_date_plus_1.setTime(create_date_plus_1.getTime() + 86400000);
  let format_current_date_plus_1 = moment(create_date_plus_1).format();
  let currentdate = moment(new Date()).format();

  useEffect(() => {

    let search = window.location.href.substring(window.location.href.lastIndexOf('?') + 1);
    var encryptURL = btoa('order_id=PGA-07062023-29&cust_id=2&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0aW1lIjoiV2VkIEp1biAwNyAyMDIzIDExOjI0OjAwIEdNVCswNzAwIChXZXN0ZXJuIEluZG9uZXNpYSBUaW1lKSIsImlkIjoyLCJuYW1lIjoiUFQgVFJJIiwiZW1haWwiOiJ0cmlpbnRhbnJhbmlAZ21haWwuY29tIiwiaWF0IjoxNjg2MTExODQwfQ.ZXaPVRCJ-3qd2rgkgYeMtiLLOOW20Q-lxzxcFIyhdOk');
    // console.log('encryptURL',encryptURL)
    var decryptURL  = atob(search);
    // console.log('decryptURL',decryptURL)
    let params      = new URLSearchParams(decryptURL);
    const order_id  = params.get("order_id");
    const cust_id   = params.get("cust_id");
    const token     = params.get("state");

    async function updatedataa() {
      if (order_id) {
        await setOrder_id(order_id);
        await setToken(token);
        
        //get data order by trans code
        await dispatch(orderByTransCode({ trans_code: order_id, cust_id: cust_id, token: token }));
        await dispatch(getOprByOprId({ opr_id: dataOrder.opr_id, token: token }));
        // await fetch(
        //   `https://api.sandbox.midtrans.com/v2/${order_id}/status`,
        //   {headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json',
        //     Authorization: 'U0ItTWlkLXNlcnZlci1pY3ZLekIxVTNKZmpyZ2RwNXBkbjAyVXQ6',
        // }}
        // )
        //   .then(function (response) {
        //     console.log("res", response?.json)
        //   })
        //   .catch(function (error) {
        //     console.log("err", error.response)
        //   });

        // variable temporary update data
        await setTempDataUpdate({
          trans_code: order_id,
          trans_status: 0,
          date_today: new Date(),
          opr_id: dataOrder.opr_id,
          cust_id: dataOrder.cust_id,
        });

        await setTempDataUpdateNotifNewOrder({
          trans_status: 0,
          opr_id: dataOrder.opr_id,
          cust_id: dataOrder.cust_id,
        });
      }
    }
    updatedataa();
    checkDate();
  }, [order_id]);

  const batalkanTransaksi = async (StatusTransactionUpdate, token) => {
    console.log('StatusTransactionUpdate',StatusTransactionUpdate)
    console.log('token',token)
    await axios
      .post(
        `https://api-pga-cust-develop.panggil-aku.com/api/v1/customer/update_status_trans`,
        StatusTransactionUpdate,
        {headers : {Authorization : `${token}`}},

      )
      .then((res) => {
        dispatch(
          orderByTransCode({ trans_code: res.data.data.setData.trans_code, token:token })
          );
        if (currentdate > format_current_date_plus_1) {
        Swal.fire({
          icon: "info",
          title: "Transaction Canceled",
          text: "Transaction canceled by system because has passed a day & your fund will refund",
        }).then((res) => {
          if(res.isConfirmed) {
            toHistory()
          }
        })
        } else {
          Swal.fire({
            icon: "success",
            title: "Transaction Canceled",
            text: "Please Check your transaction!!",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // FUNCTION AUTO CANCEL
  function checkDate() {
    if (dataOrder?.trans_status == 0) {
      if (currentdate > format_current_date_plus_1) {
          batalkanTransaksi({
            trans_code: order_id,
            trans_status: 3,
            date_today: currentdate,
            cancle_by: 2,
            opr_id: dataOrder.opr_id,
            cust_id: dataOrder.cust_id,
            alasan_pembatalan: "has passed a day"
          }, Token);
      } else {
        return false
      }
    }
  }

 

  // useEffect(() => {
  //   if (dataOrder) {
  //     dispatch(UpdateOrder(tempDataUpdate));
  //   }
  // }, [dataOrder]);

  var totalHari = 0;
  var totalMandays = 0;
  var biaya_payment_gateway = dataOrder.biaya_payment_gateway;
  var total_pembayaran = dataOrder.price;

  // var myHeaders = new Headers();
  // myHeaders.append("Accept", "application/json");
  // myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Authorization", "SB-Mid-server-icvKzB1U3Jfjrgdp5pdn02Ut");

  // var raw = "\n\n";

  // var requestOptions = {
  //   method: 'GET',
  //   headers: myHeaders,
  //   body: raw,
  //   redirect: 'follow'
  // };

  // useEffect(() => {
  //   fetch(`https://api.sandbox.midtrans.com/v2/${order_id}/status`, requestOptions)
  //   .then(response => console.log("Ss", response))
  //   // .then(result => console.log(result))
  //   .catch(error => console.log('error', error.data));
  // },[])

  // const fetchAPIMIdtrans = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Accept", "application/json");
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append(
  //     "Authorization",
  //     "Basic U0ItTWlkLXNlcnZlci1pY3ZLekIxVTNKZmpyZ2RwNXBkbjAyVXQ6"
  //   );

  //   const myRequest = new Request(
  //     `https://api.sandbox.midtrans.com/v2/PGA-16122022-3/status`,
  //     {
  //       method: "GET",
  //       headers: myHeaders,
  //       mode: "no-cors",
  //       redirect: "follow",
  //     }
  //   );

  //   fetch(myRequest)
  //     .then((res) => console.log("res", res))
  //     .catch((err) => console.log("err", err?.data?.Response));
  //   console.log("id", order_id);
  // };

  // useEffect(() => {
  //   fetchAPIMIdtrans();
  // }, []);

  return (
    <>
      {loadingDataOrder ? (
        <Spinner color="primary" />
      ) : (
        <>
          <div style={{ overflowX: "hidden" }}>
            <div className="" style={{ background: "white" }}>
              <Card className="">
                <Card.Body className="text-center">
                  <div className="d-flex justify-content-center">
                    <img src={logoVertikal} className="img-pga" alt="logo" />
                  </div>
                  {/* <p
                    style={{
                      fontFamily: "Poppins",
                      margin: 10,
                      color: "#4a4a4a",
                    }}
                  >
                    {transaction_status === "pending" &&
                      "Untuk Selanjutnya Harap Selesai kan Pembayaran nya jika belum bayar, dan Silahkan Update Status Pembayaran di Halaman History"}
                  </p> */}
                  <p className="text-contain text-center">Transaksi</p>
                  {/* <p className="text-contain">
                    Date : {date_today ? date_today : "10/21/2022"}
                  </p> */}
                  <p className="text-contain">
                    Status :{" "}
                    {/* {transaction_status ? (
                      transaction_status === "settlement" ||
                      transaction_status === "capture" ? ( */}
                    <span className="text-status">Pembayaran Berhasil</span>
                    {/* ) : (
                        <span
                          className="text-status"
                          style={{ color: "green", fontWeight: "bold" }}
                        >
                          {transaction_status == "PAID"
                            ? "Sudah di Bayar"
                            : transaction_status === "pending"
                            ? "Belum Dibayar"
                            : ""}
                        </span>
                      )
                    ) : (
                      <span className="text-status">Error</span>
                    )} */}
                  </p>
                  {/* <Button
                    className="btn-continue"
                    type="submit"
                    onClick={() => toHistory()}
                  >
                    Continue
                  </Button> */}
                </Card.Body>
              </Card>
            </div>
            <div
              style={{
                backgroundColor: "purple",
                paddingBottom: "10px",
                margin: 10,
              }}
            >
              <div>
                <table
                  className="table"
                  style={{ fontSize: 10, color: "white", fontWeight: "bold" }}
                >
                  <tr>
                    <td>Detail Pesanan</td>
                  </tr>
                </table>
                {/* Detail Pemesanan */}
                <Card
                  style={{ marginTop: -20, marginLeft: 10, marginRight: 10 }}
                >
                  <div className="row" style={{ padding: 10 }}>
                    <div className="col-1">
                      <img
                        style={{
                          width: "30px",
                          height: "30px",
                          border: "1px solid purple",
                          borderRadius: 5,
                        }}
                        alt="detail"
                        src={logoVertikal}
                      />
                    </div>
                    <div
                      className="col-8 ml-2"
                      style={{ alignItems: "center", display: "grid" }}
                    >
                      <div className="row">
                        <div
                          className="col-12"
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {dataOrder?.opr_name}
                        </div>
                        <div className="col-12" style={{ fontSize: 9 }}>
                          <i>
                            Tanggal Pesanan :{" "}
                            {moment(dataOrder.create_date).format(
                              "dddd, MMMM Do, YYYY"
                            )}
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{ paddingLeft: 10, fontSize: 10 }}
                  >
                    <div
                      className="col-11 ml-2 mr-2 "
                      style={{ borderBottom: "1px solid silver" }}
                    >
                      {dataOrder.notes != "undefined" ? (
                        <p>
                          <i>Catatan: {dataOrder.notes}</i>
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <Card.Body>
                    <p style={{ fontWeight: "bold", fontSize: 12 }}>
                      Informasi Perusahaan dan Standar yang di Audit
                    </p>
                    <table
                      border="1"
                      className="table table-bordered"
                      style={{ fontSize: 10 }}
                    >
                      {/* <tr>
                        <td>{order_id ? order_id : "order ID not available"}</td>
                        <td align="right">Lihat Progress</td>
                      </tr> */}
                      <tr>
                        <td>Perusahaan</td>
                        <td align="right">{dataOrder.company_service}</td>
                      </tr>
                      <tr>
                        <td>Audit Standard</td>
                        <td align="right">{dataOrder?.data_standard?.map((e) => ` ( ${e?.skill_name} ) `)}</td>
                      </tr>
                    </table>

                    <p style={{ fontWeight: "bold", fontSize: 12 }}>
                      Lokasi Audit
                    </p>
                    <p style={{ fontSize: 10 }}>
                      {dataOrder.detail_address} Kec. {dataOrder.nama_kecamatan}{" "}
                      Kota {dataOrder.nama_kota} Prov. {dataOrder.nama_provinsi}
                    </p>

                    <p style={{ fontWeight: "bold", fontSize: 12 }}>
                      Detail Audit dan Rincian Pembayaran
                    </p>

                    <table
                      className="table"
                      style={{ fontSize: 10, background: "#e2b4e2" }}
                    >
                      <tr>
                        <td>Metode Pembayaran</td>
                        <td align="right">
                          Transfer Bank
                          {/* {
                            dataOrder.metode_pembayaran == "vabca" ? "BCA Virtual Account" : 
                            (dataOrder.metode_pembayaran == "vamandiri" ? "Mandiri Virtual Account" : 
                            (dataOrder.metode_pembayaran == "vabni" ? "BNI Virtual Account" : 
                            (dataOrder.metode_pembayaran == "vapermata" ? "Permata Virtual Account" : 
                            (dataOrder.metode_pembayaran == "vabri" ? "BRI Virtual Account" : "-"))))
                          } */}
                        </td>
                      </tr>
                    </table>

                    <table
                      border="1"
                      className="table table-bordered"
                      style={{ fontSize: 10 }}
                    >
                      <tr>
                        <td align="center">
                          <b>Tanggal Audit</b>
                        </td>
                        <td align="center">
                          <b>Hari</b>
                        </td>
                        <td align="center">
                          <b>Mandays/Hari</b>
                        </td>
                      </tr>
                      {dataOrder?.data_schedule?.map(
                        (inputField) => (
                          (totalHari += inputField.total_booked_per_day),
                          (totalMandays += inputField.mandays_per_day),
                          (
                            <tr>
                              <td>
                                {moment(inputField.date_booked).format(
                                  "dddd, MMMM Do, YYYY"
                                )}
                              </td>
                              <td align="center">
                                {inputField.total_booked_per_day}{" "}
                              </td>
                              <td align="right">
                                {new Intl.NumberFormat("en-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                }).format(inputField.mandays_per_day)}
                              </td>
                            </tr>
                          )
                        )
                      )}
                      <tr>
                        <td>
                          <b>Total Mandays</b>
                        </td>
                        <td align="center">
                          <b>{totalHari}</b>
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-ID", {
                            style: "currency",
                            currency: "IDR",
                          }).format(totalMandays)}
                        </td>
                      </tr>
                      {/* <tr>
                        <td colSpan={2}><b>Biaya Penanganan</b></td>
                        <td align="right">
                          { new Intl.NumberFormat('en-ID',{ style: 'currency', currency: 'IDR' }).format(biaya_payment_gateway)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}><b>Total Pembayaran</b></td>
                        <td align="right">
                          <b>
                            { new Intl.NumberFormat('en-ID',{ style: 'currency', currency: 'IDR' }).format(total_pembayaran)}
                          </b>
                        </td>
                      </tr> */}
                    </table>

                    {dataOrder.trans_status == 0 ? (
                      <Button
                        className="btn-continue"
                        type="submit"
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure ?",
                            text: "You will cancel this transaction!!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, I do !!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              batalkanTransaksi({
                                trans_code: order_id,
                                trans_status: 3,
                                date_today: new Date(),
                                cancle_by: 0,
                                opr_id: dataOrder.opr_id,
                                cust_id: dataOrder.cust_id,
                              }, Token);
                            }
                          });
                        }}
                      >
                        {" "}
                        <i>Batalkan Transaksi</i>
                      </Button>
                    ) : null}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ManualFinish;
