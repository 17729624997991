import { combineReducers } from "redux";
import PaymentTrans from "./paymentTrans";
import GetOprByOprId from "./getOprByOprId";

const reducer = combineReducers({
  PaymentTrans,
  GetOprByOprId
});

export default reducer;
