import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Finish from "../Payment/finish";
import Failure from "../Payment/failure";
import UnFinish from "../Payment/unfinish";
import ManualFinish from "../Payment/manualFinish";
import ManualFailure from "../Payment/manualFailure";
import ManualUnFinish from "../Payment/manualUnfinish";

// import SPBU from "../SPBU";

const MainApp = () => {
  return (
    <Router>
      <Switch>
        {/* web version */}
        <Route path="/payment/finish" component={Finish} exact />
        <Route path="/payment/failure" component={Failure} exact />
        <Route path="/payment/unfinish" component={UnFinish} exact />

        <Route path="/payment/manualFinish" component={ManualFinish} exact />
        <Route path="/payment/manualFailure" component={ManualFailure} exact />
        <Route
          path="/payment/manualUnfinish"
          component={ManualUnFinish}
          exact
        />
      </Switch>
    </Router>
  );
};

export default MainApp;
